.Spaces {
    padding: 10px;
    height: fit-content;
    margin-top: 20px;

}

.Spaces .title {
    font-size: large;
    font-weight: bold;

}

.Spaces .list {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: 1px lightgray solid;
    position: relative;
}

.Spaces .fab {
    position: absolute;
    bottom: 5px;
    right: 5px;
}


.AddSpaceDialog {
    padding:10px;

}

.AddSpaceDialog .fullwidth {
    padding-bottom: 10px;

}

.AddSpaceDialog .thirds {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.AddSpaceDialog .third {
    width: calc(33% - 5px);

}

.AddSpaceDialog .buttonDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

