.RequestDescriptionPage {
    padding: 10px;

}

.RequestDescriptionPage_title {
    font-size: xx-large;
    font-weight: 500;
    color: rgb(18, 100, 100);
    padding-bottom: 10px;
}

.RequestDescriptionPage_message_title {
    font-size: large;
    font-weight: 500;
    border-bottom: solid;
    border-width: 1px;
    border-color: lightgray;
}

.RequestDescriptionPage_message {
    min-height: 100px;
    position: relative;
}

.RequestDescriptionPage_message .icon-button {
    position: absolute;
    bottom: 5px;
    right: 5px;

}
