.AddAssetDialogContent {
  padding: 10px;
  max-height: 80vh;
  overflow: scroll;
}

.AddAssetDialogContent .fullwidth {
  padding-bottom: 10px;
}

.AddAssetDialogContent .halves {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddAssetDialogContent .half {
  width: calc(50% - 5px);
}

.AddAssetDialogContent .buttonS {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.AddAssetDialogContent .addExtra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.AddAssetDialogContent .addExtra .Extratitle {
    font-weight: medium;
}

.AddAssetDialogContent .addExtra .conButton {
}



.AddAssetDialogContent .title {
    width: 100%;
    font-size: large;
    font-weight: bold; 
    position: relative;
}


.AddAssetDialogContent .title .listTitles {
    position: absolute;
    right: 0px;
    top: 5px;
    display: flex;
    flex-direction: row;
    font-size: x-small;
    color: gray;
}


.AddAssetDialogContent .title .listTitles > *:nth-child(3) {
    margin-right: 10px;
}
.AddAssetDialogContent .title .listTitles > *:nth-child(2) {
    margin-right: 17px;
}
.AddAssetDialogContent .title .listTitles > *:nth-child(1) {
    margin-right: 17px;
}



.AddAssetDialogContent .list {
    width: 100%;
    max-height: 400px;
    min-height: 100px;
    overflow: scroll;
    border-radius: 5px;
    border: solid 1px lightgray;
    margin-top: 20px;
    list-style-type: none;
}


.AddAssetDialogContent .AddPhotoIconButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
}