.PropertyTradesman {
    padding: 10px;
    height: fit-content;

}

.PropertyTradesman .title {
    font-size: large;
    font-weight: bold;

}

.PropertyTradesman .list-section {
    border: solid 1px lightgray;
    border-radius: 5px;
    min-height: 100px;
    max-height: 500px;
    position: relative;

}


.PropertyTradesman .fabButton {
    position: absolute;
    bottom: 5px;
    right: 5px;

}
