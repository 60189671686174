.EditBasicDetails {
    padding: 10px;
} 

.EditBasicDetails .fullwidth {
    padding-top: 10px;
    padding-bottom: 10px;

}

.EditBasicDetails .sections {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.EditBasicDetails .smaller {
    width: calc(40% - 5px);

}

.EditBasicDetails .bigger {
    width: calc(60% - 5px);

}
