.EditSpace {
    padding: 10px;

}

.EditSpace .title {
    font-size: large;
    font-weight: bold;
    margin-bottom: 10px;
}

.EditSpace .fullwidth {
    width: 100%;
    margin-bottom: 10px;

}

.EditSpace .thirds {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.EditSpace .third {
    width: calc(33% - 5px);

}

.EditSpace .buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}
