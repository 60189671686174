.AddressEdit {
    padding: 10px;

}

.AddressEdit .fullwidth {
    margin-bottom: 10px;

}

.AddressEdit .halves {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   

}


.AddressEdit .half {
    width: calc(50% - 5px);

}
