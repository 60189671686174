.SpaceImagesEdit {
    padding: 10px;
    width: calc(100% - 20px);
    position: relative;
}

.SpaceImagesEdit .title {
    font-size: large;
    font-weight: bold;

}


.SpaceImagesEdit .ImageList {
    height: 90vh;
    max-height: 500px;
    border-radius: 5px;
    border:  solid 1px lightgray;
    width: 100%;
    list-style-type: none;
    overflow: scroll;
    padding-bottom: 10px;
}




.SpaceImagesEdit .dropSection {
    width: calc(100% - 20px);
    height: 100px;
    background-color: rgb(18, 100, 100);
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 5px;
    color: white;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.SpaceImagesEdit .dragging {
    color: rgb(18, 100, 100);

}
