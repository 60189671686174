

@media screen and (min-width: 1200px) {
    .PropertyJobs {
        margin-top: 0px;
        max-width: calc(50% - 30px);
        width: 575px;
        padding: 10px;
        height: 40vh;
    }
}



@media screen and (max-width: 1200px) {
    .PropertyJobs {
        margin-top: 0px;
        width: inherit;
        padding: 10px;
        height: 70vh;
        margin-bottom: 10px;

    }
}

.PropertyJobs .title {
    font-size: large;
    font-weight: bold;
}

.PropertyJobs .list {
    width: 100%;
   height: calc(100% - 40px);
   overflow: scroll;
   position: relative;
}

.PropertyJobs .list  .iconButton {
    position: absolute;
    bottom: 5px;
    right: 5px;

    
}