.AssetDocumentsCard {
    padding: 10px;

}

.AssetDocumentsCard .title {
    font-size: large;
    font-weight: bold;

}

.AssetDocumentsCard .Content {
    width: 100%;
    height: calc(100% - 30px);
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.AssetDocumentsCard .list {
    margin-top: 10px;
}