.TradesmanProfilePage  {

}
.TradesmanProfilePage .card {
    padding: 10px;
    width: calc(100vw - 40px);
    max-width: 800px;
    position: relative;
    margin-top: 50px;


}
.TradesmanProfilePage .topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.TradesmanProfilePage .subtitle {
    color: gray;

}
.TradesmanProfilePage .stars {

}
.TradesmanProfilePage .title {
    font-size: xx-large;
    font-weight: bold;
    width: calc(100% - 120px);

}
.TradesmanProfilePage .logo {
    position: absolute;
    right: 10px;
    top: 50px

}
.TradesmanProfilePage .address {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    width: calc(100% - 120px);


}
.TradesmanProfilePage .bio {
    margin-top: 50px;

}
.TradesmanProfilePage .chips {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
    background-color: rgba(211, 211, 211, 0.2);
    margin-top: 10px;
    height: fit-content;
    overflow: hidden;
    margin-bottom: 20px;

}


.fabButton {
    position: absolute;
    right: 10px;
    bottom: 10px;
}