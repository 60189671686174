.JobPage {
    width: calc(100% - 40px);
    max-width: 1200px;
    padding: 10px;
    height: calc(100% - 110px);
    position: absolute;
    top: 60px;
}

.JobPage .basicDetails {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: right;
}

.JobPage .basicDetails .title {
   font-size: x-large;
   font-weight: bold;
   text-transform: capitalize;
   color: rgb(18, 100, 100);
}


.JobPage .basicDetails .subtitle {
   color: gray
}




.JobPage .Content {
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.JobPage .Content > * {
    padding: 10px;
}

.JobPage .Content > * .title {
    font-size: large;
    font-weight: bold;
}