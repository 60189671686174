.EditAccess {
    padding: 10px;
    position: relative;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

}
.EditAccess .imageSection {
    height: 300px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    border-radius: 5px;
}

.EditAccess .image {
    height: inherit;
    width: inherit;
    position: relative;
    border-radius: 5px;
    object-fit: cover;
}


.EditAccess .iconButton {
    position: absolute;
    bottom: 10px;
    right: 10px;

}
.EditAccess .fullwidth {

}

.EditAccess  .Loading {
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);

}
