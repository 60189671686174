.SelectPropertiesDialog {
    padding: 15px;
    position: relative;
}

.SelectPropertiesDialog  .Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    left: 0px;
    top: 0px;
    z-index: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


}

.SelectPropertiesDialog .title {
    font-size: large;
    font-weight: bold;
}

.SelectPropertiesDialog .list {
    border-radius: 5px;
    background-color: rgba(211, 211, 211, 0.2);
    height: 300px;
    overflow: scroll;

}

.SelectPropertiesDialog .buttonBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;

}
