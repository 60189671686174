.AssetPhotosCard {
    padding: 10px;

}

.AssetPhotosCard .title {
    font-size: large;
    font-weight: bold;

}

.AssetPhotosCard .Content {
    width: 100%;
    height: calc(100% - 30px);
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AssetPhotosCard .navBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(18, 100, 100);
}


.AssetPhotosCard .navBar:first-child > * {
    transform: rotate(180deg);
    
}

.AssetPhotosCard .navBar:hover {
    background-color: rgba(18, 100, 100, 0.1);
}

.AssetPhotosCard .imageCentre {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}


.AssetPhotosCard .imageCentre .image {
   max-width: 100%;
   max-height: 100%;
   object-fit: contain;

 
}
