.RequestStartPage {
    padding: 10px;
}

.RequestStartPage_img_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RequestStartPage_img {
    width: 50%;
    border-bottom: solid;
    font-weight: 1px;
    color: lightgray;
    padding-bottom: 10px;
}


.RequestStartPage_title {
    font-size: xx-large;
    font-weight: 500;
    text-align: center;
    padding-top: 20px;
    color: rgb(18, 100, 100);
}

.RequestStartPage_subtitle_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RequestStartPage_subtitle {
    max-width: 90%;
    text-align: center;

}

