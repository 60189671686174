.TenantCard {
    width: calc(100% - 20px);
   margin-top: 20px;
    
    height: fit-content;
    padding: 10px;
}


.TenantCard .title {
    font-weight: bold;
    font-size: large;

}

.TenantCard .listSection  {
    position: relative;
    height: 100px;
    border-radius: 5px;
    border: 1px solid lightgrey;

}


.TenantCard .listSection .Fab {
    position: absolute;
    right: 5px;
    bottom: 5px;
}
