.JobExtraDetails {
    width: calc(50% - 25px);
    height: 300px;
    position: relative;
}


@media screen and (max-width: 1000px) {
    .JobExtraDetails {
        width: 100%;
     margin-bottom: 10px;
    }
    
} 


.JobExtraDetails .urgency {
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column;

}
.JobExtraDetails .message {
    border-radius: 5px;
    border: 1px lightgray solid;
    padding: 10px;
    margin-top: 40px;
    height: calc(100% - 100px);
    padding-bottom: 20px;
    position: relative;

}

.JobExtraDetails .messageTitle {
    font-size: small;
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    padding-left: 5px;
    padding-right: 5px;


}


.JobExtraDetails .message .button {
    position: absolute;
    bottom: 5px;
    right: 5px;
}
