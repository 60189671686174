.Description {
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
} 

.Description .caption {
    font-size: x-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
}

.Description .textField {

}
