.Dashboard {
    width: 100vw;
    height: calc(100vh - 70px);
    position: relative;
    top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;

   
}

.Dashboard .user-tag {
    position: absolute;
    right: 20px;
    top: 0px;
    border-radius: 5px;
    margin-top: 10px;
}

.Dashboard .content {
    width: calc(100% - 20px);
    max-width: 1200px;
    height: calc(100% - 70px);
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   justify-content: space-between;

}
