.AddProperty  {
    position: fixed;
    width: 100%;
    height: calc(100% - 70px);
    top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.AddProperty .card {
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 900px ;
    position: relative;
    padding: 10px;

}

.AddProperty .progress {
    color: rgb(18, 100, 100);

}


.AddProperty .slide {
    height: calc(100% - 70px);
    padding: 10px;


}

.AddProperty  .buttons {
    color:'rgb(18, 100, 100)'; 
    padding-top: 10px;
    padding-bottom: 10px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;

}



/* Introduction slide */

.IntroSlide  {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.IntroSlide .title {
    font-size: xx-large;
}

.IntroSlide .subtitle {
    font-size: large;


}

.IntroSlide .message {
    font-size: small;
    margin-top: 10px;
    color: gray;

}


/* details slide */

.InputFieldsParent { 
   width: inherit;
   height: inherit;
   display: flex;
   flex-direction: column;
   justify-content: center;

}


.inputFields  {
    width: 100%;
    height: fit-content;
    overflow: scroll;
    max-height: inherit;

}


.inputFields  .title {
    font-size: large;
    font-weight: bold;
    color: rgb(18, 100, 100);


}

.inputFields  .subtitle {
    color: gray;

}


.inputFields .fullwidth {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;

}

.inputFields .halves {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.inputFields .half {
    width: calc(50% - 5px);

}

