.Photos {
    margin-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;


}

.loading {
    width: 100%;
    height: 120%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10000;

}

.Photos .title {
    font-size: large;
    font-weight: bold;
    color: rgb(18, 100, 100);

}

.Photos .List {
    max-height: calc(100% - 100px);
    overflow: scroll;
}

.Photos .uploadSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
