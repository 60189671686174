.AddAsset {
  padding: 10px;
}

.AddAsset .progress {
  color: rgb(18, 100, 100);
}

.AddAsset .Content {
  height: 70vh;
  max-width: 700px;
}

.AddAsset .Content > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.AddAsset .navigation {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* introduction slide */
.IntroSlide {
  width: inherit;
  height: inherit;
}

.IntroSlide .title {
  font-size: xx-large;
  font-weight: bold;
  color: rgb(18, 100, 100);
}

.IntroSlide .description {
  text-align: left;
}

/* Title slide */
.Title {
  width: inherit;
  height: inherit;
}

.AddAsset .fullwidth {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Event details */
.EventDetails {
  width: inherit;
  height: inherit;
}

.EventDetails .sixtyForty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.EventDetails .left {
  width: calc(60% - 5px);
}

.EventDetails .right {
  width: calc(40% - 5px);
}

/* Expense */

.Expense {
  width: inherit;
  height: inherit;
}

.Expense .title {
  font-size: xx-large;
  font-weight: bold;
  color: rgb(18, 100, 100);
}

.Expense .subtitle {
  align-items: center;
  font-size: small;
  width: 90%;
  margin-bottom: 10px;
}

.Expense .subtitle .icon {
  background-color: red;
}

/* Documents */
.Documents {
  width: inherit;
  height: inherit;
}

.Documents .list {
  list-style-type: none;
  min-height: 120px;
  position: relative;
  max-height: calc(70vh - 100px);
  overflow: scroll;
  padding-bottom: 70px;
}
.Documents .list .secondaryActions {
    background-color: white;
}

.Documents .list .fab {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.Documents .titles {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: x-small;
  margin-bottom: 10px;
}

.Documents .Addtitle {
  margin-left: 10px;
  font-size: x-large;
  margin-bottom: 20px;
  font-weight: bold;
  color: rgb(18, 100, 100);
  border-bottom: solid 1px lightgray;
}

.Documents .title {
  margin-left: 10px;
}

.Documents .titles .title:nth-child(2) {
  margin-right: 2px;
}

.Documents .titles .title:nth-child(3) {
  margin-right: 15px;
}



.Success {
  height: inherit;
  display: flex;
  flex: row;
  justify-content: center;
  align-items: center;
}

.Success .circle {
  border: solid 4px rgb(18, 100, 100);
  height: 170px;
  width: 170px;
  border-radius: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.Success .title {
  font-size: xx-large;
  font-weight: bold;
  color: rgb(18, 100, 100);

}

.Success .message {

}
