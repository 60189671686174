.Properties {
    position: relative;
    width: 100%;
    height: calc(100% - 90px);
    margin-top: 60px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.Buildfab {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 10000;
}

.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;
}

.Properties .address-section {
    position: absolute;
    right: 60px;
    text-align: right;
}

.Properties .primary {
    font-weight: 500;
}

.Properties .secondary {
    font-weight: 350;
}

.Properties .edit-button {
    position: absolute;
    right: 10px;
    top: 15px;
}



@media  screen and (max-width: 1100px) {
    .Properties .content {
        width: 100%;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        }
    

    
}

@media  screen and (min-width: 1100px) {
    .Properties .content {
        width: 100%;
        max-width: 1400px;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        }
.Properties .content > *:nth-child(1) {
    width: 40%;
    padding: 10px;
}

.Properties .content > *:nth-child(2) {
    width: 30%;
    padding: 10px;
}
.Properties .content > *:nth-child(3) {
    width: 30%;
    padding: 10px;
} 
}






.Properties .content > *:last-child > *:nth-child(2) {
    margin-top: 20px;
} 
.Properties .content > *:last-child > *:nth-child(3) {
    margin-top: 20px;
} 


