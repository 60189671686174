.EditAssetDetails {
    padding: 10px;

}

.EditAssetDetails .fullwidth {
    padding-top: 5px;
    padding-bottom: 5px;

}
