.RequestTitlePage {
    padding: 10px;
  }
  
  .RequestTitlePage_title {
    font-size: xx-large;
    font-weight: 500;
    color: rgb(18, 100, 100);
    padding-bottom: 10px;
  }
  
  .RequestTitlePage_msg_title {
    font-size: large;
    font-weight: 500;
    border-bottom: solid;
    border-width: 1px;
    border-color: lightgray;
  }
  
  .RequestTitlePage_msg {
    min-height: 20px;
    padding-bottom: 10px;
  }