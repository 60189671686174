.CreateNewJob {
    width: calc(100% - 20px);
    height: calc(100% - 120px);
    top: 80px;
    padding: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.CreateNewJob .content  {
    width: calc(100% - 20px);
    max-width: 600px;
    height: 800px;
    max-height: 100%;
    position: relative;
    padding: 10px;

}

.CreateNewJob .content .slide {
    height: calc(100% - 70px);
}


.CreateNewJob .content .buttons {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
