.TenantAddDialog {
    padding:10px

}

.TenantAddDialog .title {
    font-size: large;
    font-weight: bold;

}

.TenantAddDialog .fields {

}

.TenantAddDialog .fullwidth {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;

}

.TenantAddDialog .halves {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;



}

.TenantAddDialog .half {
    width: calc(50% - 5px);

}

.TenantAddDialog .buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
