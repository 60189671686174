.UserDetailsContent {
    padding: 10px;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10000;
    


}

.UserDetailsContent .loading .child {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.UserDetailsContent .display-section {
margin-bottom: 20px;
}


.UserDetailsContent .content {
    display: flex;
    flex-direction: row;
    height: 75px;

    align-items: center;
}

.UserDetailsContent .avatar {
    margin-right: 30px;
    position: relative;

}

.UserDetailsContent .upload-button {
    position: absolute;
    right: -20px;
    bottom: -20px;

}

.UserDetailsContent .text-section {
   align-content: center;
   display: flex;
   flex-direction: column;
   align-items: center;



}

.UserDetailsContent .name {
    font-size: x-large;
    font-weight: bold;

}

.UserDetailsContent .email  {
color: gray;
}


.UserDetailsContent .subtitle {
    font-size: medium;
    font-weight:500;
    border-bottom: solid 1px lightgray;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.UserDetailsContent .text-fields {

}

.UserDetailsContent .halves {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.UserDetailsContent .half {
    width: calc((100% - 10px )/ 2);

}

.UserDetailsContent .fullwidth {
    margin-bottom: 10px;

}
