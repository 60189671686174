.SpaceNotes {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 0px;
    height: 40vh;
}

.SpaceNotes .notes {
    width: calc(100% - 20px);
    height: calc(100% - 40px);
    border-radius: 5px;
    padding: 10px;
    overflow: scroll;
    position: relative;
}

.SpaceNotes .notes .fab {
    position: absolute;
    bottom: 10px;
    right: 10px;

}