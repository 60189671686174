.AssetDetails {
    padding: 10px;
    height: min-content;
    position: relative;
    background-color: red;
}

.AssetDetails .title {
    font-size: large;
    font-weight: bold;
    font-size: large;
}

.AssetDetails .section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 3px;
    height: 20px;
}


.AssetDetails .section > .label {
   margin-right: 5px;
   font-size: medium;
   font-weight: bold;
   color: rgb(18, 100, 100);

}

.AssetDetails .IconButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
