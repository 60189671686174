.RequestRelatedSpace_content {
    padding: 10px;
  }
  
  .RequestRelatedSpace_title {
    font-size: xx-large;
    font-weight: 500;
    color: rgb(18, 100, 100);
    padding-bottom: 10px;
  }
  
  .RequestRelatedSpace_message_title {
    font-size: large;
    font-weight: 500;
    border-bottom: solid;
    border-width: 1px;
    border-color: lightgray;
  }
  
  .RequestRelatedSpace_message {
    min-height: 20px;
    padding-bottom: 10px;
  }
  