.TradesmanSearchResults {
    width: calc(100% - 20px);
    max-width: 1000px;

}

.TradesmanSearchResults .Loading {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 500;
}


.TradesmanSearchResults .topBar {
    position: relative;
    align-items: center;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: solid 1px lightgray;
    width: calc(100% - 20px);
    margin-left: 10px;  
    
}


.TradesmanSearchResults .searchButton {
    position: absolute;
    right: 10px;
    z-index: 50;

}

.TradesmanSearchResults .searchBar {
    margin-bottom: 10px;
    padding: 10px;
    width: calc(100% - 100px);
    position: absolute;
    right: 0px;
    top: 0px;
    transition: all 500ms;
}


.TradesmanSearchResults .searchBarHidden {
    margin-bottom: 10px;
    padding: 10px;
    width: calc(100% - 200px);
    position: absolute;
    right: calc(100% - 165% - 200px);
    top: 0px;
    transition: all 500ms;

}


.TradesmanSearchResults .title {
    font-size: large;
    font-weight: bold;
    margin-left: 10px;
    padding-bottom: 10px;
    width: fit-content;
}

.TradesmanSearchResults .results {
    width: 100%;
    min-height: 200px;
}



.TradesmanSearchResults .searchResults {
    width: calc(100% - 20px);
    margin-left: 10px;

}