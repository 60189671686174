

@media screen and (min-width: 1200px) {
    .PropertiesCard {
        margin-top: 0px;
        max-width: calc(50% - 30px);
        width: 575px;
        padding: 10px;
        height: 40vh;
    }
}



@media screen and (max-width: 1200px) {
    .PropertiesCard {
        margin-top: 0px;
        width: inherit;
        padding: 10px;
        height: 70vh;
        margin-bottom: 10px;
    }
}



.PropertiesCard .title {
    margin-bottom: 0px;
    font-size: large;
    font-weight: bold;}





.PropertiesCard .list-section {
    height: calc(100% - 40px);
    overflow: scroll;
    border-radius: 5px;
    position: relative;
}


.PropertiesCard .list-section .add-property-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
}
