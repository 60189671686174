.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

* {
    font-family: 'Poppins', sans-serif;
}
* ::marker {
    display: none;
  }
