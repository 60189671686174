.ProcessTenantRequest  {
    position: absolute;
    top: 70px;
    height: calc(100% - 70px);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.ProcessTenantRequest .Content {
    width: 100%;
    max-width: 800px;
    height: calc(100% - 30px);
    padding: 10px;
    position: relative;
}

.ProcessTenantRequest  .progress {
    color: rgb(18, 100, 100);

}

.ProcessTenantRequest .slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

}

.ProcessTenantRequest  .navigation {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;

}

