.JobPhotos {
    width: calc(50% - 25px);
    height: 300px;
}

@media screen and (max-width: 1000px) {
    .JobPhotos {
        width: 100%;
     margin-bottom: 10px;
    }
    
}


.JobPhotos .components {
    width: calc(100%);
    height: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.JobPhotos .navigation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(18, 100, 100);
}

.JobPhotos .navigation:first-child {
   transform: rotate(180deg);
}

.JobPhotos .navigation:hover {
   background-color: rgba(18, 100, 100, 0.2);
 }

.JobPhotos .imageParent {
    display: flex;
    flex: row;
    justify-content: center;
    align-items: center;
}

.JobPhotos .image {
    object-fit: contain;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    

}

