.AssetHistory {
    padding: 10px;
    position: relative;

}

.AssetHistory .title {
    font-size: large;
    font-weight: bold;
}

.AssetHistory .list {
   border-radius: 5px;
   width: 100%;
   height: 50vh;
   max-height: 300px;
   overflow: scroll;
}

.AssetHistory .IconButton {
    position: absolute;
    bottom: 15px;
    right: 15px;

 }

