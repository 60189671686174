.Login {
    max-width: 600px;
    width: 100%;
    padding: 10px;
    position: relative;
}

.Login .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 500;
}

.Login .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;

}

.Login .logo .image {
    width: 80%;
}

.Login .title {
    font-size: xx-large;
    font-weight: 800;
    text-align: center;


}

.Login .fields {

}

.Login .field {
    margin: 10px;

}

.Login .buttons {
    width: calc(100% - 20px);
    margin-left: 10px;
    position: relative;
    height: 50px;

}

.Login .button-right {
    transition: all 1s ease;
    position: absolute;
    margin-left: calc(100% - 65px);
}
.Login .button-left {
    transition: all 1s ease;
    position: absolute;
    margin-left: 0px;
  
}


.Login .links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login .link-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: large;
    margin-bottom: 5px;


}

.Login .link-section .link {
    margin-left: 5px;
    color: rgb(18, 100, 100);
    cursor: pointer;
    
    
}
