@media screen and (min-width: 1200px) {
    .TenantMaintenaceRequests {
        margin-top: 10px;
        max-width: calc(50% - 30px);
        width: 575px;
        padding: 10px;
        height: 40vh;

    }
}



@media screen and (max-width: 1200px) {
    .TenantMaintenaceRequests {
        margin-top: 0px;
        width: inherit;
        padding: 10px;
        height: 70vh;
        margin-bottom: 10px;

    }
}




.TenantMaintenaceRequests .title {
    font-weight: bold;
    font-size: large ;
}

.TenantMaintenaceRequests .list {
    width: 100%;
    max-height: 300px;
    overflow: scroll;
}


.TenantMaintenaceRequests .placeholderMessage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: gray;
    height: calc(100% - 30px);
    width: 100%;
}