.AssetQuickDetails  {
    position: absolute;
    top: 20px;
    right: 30px;
    text-align: right;
    display: flex;
    flex-direction: row ;
}

.AssetQuickDetails .title {
    font-size: x-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
    min-width: 200px;
}

.AssetQuickDetails .warranty {
    color: gray;
}

.AssetQuickDetails .installed {
    color: gray;
}


.AssetQuickDetails .icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    left: 0px;
    bottom: 0px;
}