.JobStart {
    padding: 10px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.JobStart .title {
    font-size: x-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
}

.JobStart .subtitle {
}

.JobStart .helperText {
    position: absolute;
    font-size: large;
    font-weight: bold;
    color: rgb(18, 100, 100);
    bottom: 80px;
    right: 100px;
    z-index: 300;
}

.JobStart .arrow {
    position: absolute;
    transform: rotate(180deg) scaleX(-1);
    height: 200px;
    right: 0px;
    bottom: 0px;
}
