.TradesmanSearch {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.TradesmanSearch .title {
    font-size: 50pt;
    font-weight: 800;
    color: rgb(18, 100, 100);

}

.TradesmanSearch .subtitle {
    font-size: large;
    margin-bottom: 20px;
    

}

.TradesmanSearch .searchbar {
    width: 100%;
    max-width: calc(100% - 20px);

}


.TradesmanSearch .buttonSection {
    width: 100%;
    max-width: calc(100% - 20px);
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
