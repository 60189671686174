


@media screen and (min-width: 1200px) {
   .SpaceImages {
    padding: 10px;
    width: calc(48% - 20px);
    max-width: 600px;
    max-height: 400px;
    margin-bottom: 0px;
    height: 40vh;
}
}


@media screen and (max-width: 1200px) {
    .SpaceImages {
        padding: 10px;
        width: calc(100% - 20px);
        max-height: 400px;
        margin-bottom: 0px;
        height: 40vh;
        margin-bottom: 20px;
    }
}





.SpaceImages .title {
    font-size: large;
    font-weight: bold;

}


.SpaceImages .Content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: calc(100% - 30px);
    position: relative;
}


.SpaceImages .addButton {
    position: absolute;
    right: 40px;
    bottom: 10px;
}

.SpaceImages .navigation {
    height: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    color: rgb(18, 100, 100); 

}

.SpaceImages .navigation:hover {
    background-color: rgba(18, 100, 100, 0.1);
    color:'white'


}


.SpaceImages .image {
    width: calc(100% - 100px);
    object-fit: contain;
    max-height: 100%;

}

