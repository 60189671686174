.FloorPlan {
  padding: 10px;
  width: calc(100% - 20px);
  height: 40vh;
  margin-top: 20px;
  position: relative;
}

.FloorPlan .fab {
position: absolute;
bottom: 10px;
right: 10px;

}



.FloorPlanNoData {
  width: inherit;
  max-width: 600px;
  aspect-ratio: 1.4142 / 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.FloorPlan .page {
  width: 100%;
  max-width: 600px;
  height: 300px;
}

.FloorPlan .title {
  font-weight: bold;
  font-size: large;
}

.FloorPlan .parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.FloorPlan .image {
  width: 100%;
   max-height: 440px;  
   max-width: 600px;
  object-fit: contain;
 aspect-ratio: 1.4142 / 1;
  display: flex;
}




.FloorPlan .button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
