.AssetHistoryPage {
    width: calc(100vw - 20px);
    max-width: 800px;
    height: 100vh;
    position: relative;
    overflow: scroll;
   padding-top: 100px;
}


.AssetHistoryPage .cornerDetails {
    position: absolute;
    top: 150px;
    right: 0px;
    text-align: right;

}

.AssetHistoryPage .cornerDetails .title {
    font-size: x-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
}

.AssetHistoryPage .cornerDetails .date {
    color: gray;
    font-weight: bold;

}


.AssetHistoryPage .content {
    margin-top: 100px;
    width: calc(100% - 20px);
}
