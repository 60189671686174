.FloorPlanEdit {
    padding: 10px;
    position: relative;
    
}

.FloorPlanEdit .Loading {
    width: calc(100% - 20px);
    height: 250px;
   background-color: rgba(255, 255, 255, 0.8);
   z-index: 500;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}



.FloorPlanEdit .document {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

}

.floorPlanImg {
    height: 300px;
}

.FloorPlanEdit .iconButton  {
    position: absolute;
    bottom: 10px;
    right: 10px;

}
