.Type {
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
} 

.Type .caption {
    font-size: x-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
    margin-bottom: 10px;
}

.Type .textField {
   
}

.slider {
    width: calc(100% - 40px);
    margin-left: 20px;
}