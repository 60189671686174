.SpaceNotesEdit {
    padding: 10px;
}

.SpaceNotesEdit .title {
    font-size: large;
    font-weight: bold;
    margin-bottom: 10px;

}

.SpaceNotesEdit .fullwidth {
    width: 100%;
}

.buttonSection {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
