.PropertyAssets {
    height: fit-content;
    padding: 10px;
    width: calc(100% - 20px);
    position: relative;
}


.PropertyAssets .title {
    font-size: large;
    font-weight: bold;
}

.PropertyAssets .listSection {
    height: 200px;
    border-radius: 5px;
    border: 1px lightgray solid;
    position: relative;
    overflow: scroll;
}

.PropertyAssets .fab {
    position: absolute;
    bottom: 15px;
    right: 15px;

}
