

.RequestPhotosPage {
    padding: 10px;

}

.RequestPhotosPage_title {
font-size: xx-large;
    font-weight: 500;
    color: rgb(18, 100, 100);
    padding-bottom: 10px;
}

.RequestPhotosPage_img_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    height: 40vh;

}

.RequestPhotosPage_button_colomn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RequestPhotosPage_button_right_icon {
    transform: rotate(180deg);
}

.RequestPhotosPage_img_div_master {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.RequestPhotosPage_img_div_child {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;

}

.RequestPhotosPage_img_div_child .image {
    height: 40vh;
    object-fit: contain;

}


