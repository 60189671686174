

@media screen and (min-width: 1200px) {
    .Assets {
     padding: 10px;
     width: calc(48% - 10px);
     max-width: 600px;
     max-height: 400px;
     margin-bottom: 0px;
     height: 40vh;
 }
 }
 
 
 @media screen and (max-width: 1200px) {
     .Assets {
         padding: 10px;
         width: calc(100% - 20px);
         max-height: 400px;
         margin-bottom: 0px;
         height: 40vh;
         margin-bottom: 20px;
     }
 }
 


.Assets .title {
    font-size: large;
    font-weight: bold;
}

.Assets .listSection {
    height: calc(100% - 30px);
    border-radius: 5px;
    position: relative;
}

.Assets .fab {
    position: absolute;
    bottom: 5px;
    right: 5px;

}
