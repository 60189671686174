.AccessCard {
  padding: 10px;
  height: fit-content;

}
.AccessCard .title {
  font-weight: bold;
  font-size: large;
}

.AccessCard .image-section {
  min-height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AccessCard .image-section .img {
  width: calc(100%);
  margin-bottom: 10px;
  max-height: 300px;
  object-fit:cover;
}

.AccessCard .access-notes {
  border: solid 1px lightgray;
  border-radius: 5px;
  min-height: 50px;
  padding: 5px;
  position: relative;
}

.AccessCard .editButton {
  right: 5px;
  bottom: 5px;
  position: absolute;
}
