.AssetHistoryAttachmentsCard {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
}

.AssetHistoryAttachmentsCard .title {
    font-size: large;
    font-weight: bold; 
    position: relative;
    width: 100%;
}

.AssetHistoryAttachmentsCard .title .listTitles {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: row;
    font-size: x-small;
    color: gray;
}


.AssetHistoryAttachmentsCard .title .listTitles > *:nth-child(3) {
    margin-right: 10px;
}
.AssetHistoryAttachmentsCard .title .listTitles > *:nth-child(2) {
    margin-right: 17px;
}
.AssetHistoryAttachmentsCard .title .listTitles > *:nth-child(1) {
    margin-right: 17px;
}



.AssetHistoryAttachmentsCard .list {

    width: 100%;
    max-height: 400px;
    min-height: 100px;
    overflow: scroll;
    border-radius: 5px;
    border: solid 1px lightgray;
    margin-top: 20px;
    list-style-type: none;
}
