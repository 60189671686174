.Header {
    position: fixed;
    z-index: 10433200000;
    background-color: white;
    top: 0px;
    left: 0px;
    box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} 


.Header .right {
    margin-right: 20px;

}

.Header .nav-menu {
    background-color: rgb(18, 100, 100);
    position: fixed;
    right: 0px;
    top: 60px;
    width: 250px;
    height: calc(100vh - 60px);
    transition: all 320ms linear;
    z-index: 10433200000;
}

.Header .nav-menu-closed {
    background-color: rgb(18, 100, 100);
    position: fixed;
    right: -250px;
    top: 60px;
    width: 250px;
    height: calc(100vh - 60px);
    transition: all 320ms linear;

}


.Header .logo {
     height: 30px;
}


.Header .logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    font-size: large;
    cursor: pointer;
    color: white;
    margin: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.Header .logout:hover {
   background-color: rgb(237, 155, 155);

}
.Header .icon {
    margin-right: 5px;
}