.InvestorMessage {
    width: 100%;
    margin-top: 10px;
    min-height: 200px;
}

.InvestorMessage .message {
   border-radius: 5px;
   border: 1px solid lightgray;
   height: calc(100% - 50px);
   padding: 10px;

}