.SpacesPage {
    width: calc(100% - 40px);
    height: calc(100% - 100px);
    margin-top: 70px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.SpacesPage .details {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    top: 0px;
    right: 0px;
    align-items: center;
    margin-right: 10px;

}

.SpacesPage .right {
    text-align: right;
    margin-right: 10px;
}

.SpacesPage .title {
    font-size: large;
    font-weight: bold;
}

.SpacesPage .dimensions {
    color: gray;

}



@media screen and (max-width: 1200px) {
    .SpacesPage .pageContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 10px);
        margin-top: 50px;
        max-width: 1200px;
    }
}

@media screen and (min-width: 1200px) {
   .SpacesPage .pageContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - 10px);
    margin-top: 50px;
    max-width: 1200px;
} 
}

