.JobAttachments {
    width: 100%;
    margin-top: 10px;
    min-height: 300px;
}

.JobAttachments .list {
    width: 100%;
    height: calc(100% - 30px);
    position: relative;
    overflow: scroll;

}


.JobAttachments .none {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.JobAttachments .list .fab {
    position: absolute;
    bottom: 10px;
    right: 10px;

}
