.assetPage {
    width: calc(100%);
    padding: 10px;
    height: calc(100vh - 100px);
    margin-top: 50px; 
    position: relative;
}



@media screen and (min-width: 1000px) {
    .assetPage .content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 100px;
}
 .assetPage .content > *  {
    width: calc(50% - 30px);
}   
}
@media screen and (max-width: 1000px) {
    .assetPage .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        justify-content: space-between;
        margin-top: 100px;
    }

    .assetPage .content > *  {
        width: calc(100%- 20px);
    }    
}



.assetPage .content > :nth-child(3)  {
    margin-top: 20px;
}
.assetPage .content > :nth-child(4)  {
    margin-top: 20px;
}